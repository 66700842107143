import React from "react";
import footer from "../assets/png/liftrocketFooter2.png";
// import { navigate } from "gatsby";
import logo from "../assets/png/liftrocketCalculator3.png";
import facebook from "../assets/png/Facebook2.png";
import tiktok from "../assets/png/Tiktok2.png";
import instagram from "../assets/png/Instagram2.png";

export const CalLogo = () => {
  return (
    <div className="calculatorLogo">
      <img src={logo} alt="liftrocket logo" />
    </div>
  );
};
export const CalFooter = () => {


  return (
    <div className="calculatorFooter">
      <div className="calculatorFooterImg">
        <img src={footer} alt="liftrocket footer" />

      </div>


      <div className="footerSocialContainer">
        <a  target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/liftrocket/" className="footerSocial">
          <img
            src={instagram}
            alt="instagram"
          />
        </a>

        <a  target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@liftrocket.com" className="footerSocial">
          <img
            src={tiktok}
            alt="twitter"
          />
        </a>

        <a  target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/liftrockett/" className="footerSocial">
          <img
            src={facebook}
            alt="facebook"
          />
        </a>
      </div>

      <a className="calculatorFooterPP"
        target="_blank" href={"/privacypolicy"}>
        Privacy Policy</a>
      <div className="calculatorFooterCR">All rights reserved. LiftRocket, LLC.</div>

    </div>
  );
};


